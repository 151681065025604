export const GET_ABOUT_SECTION = "GET_ABOUT_SECTION";
export const GET_ABOUT_SECTION_LOADING = "GET_ABOUT_SECTION_LOADING";

export const GET_ABOUT_OFFER_SECTION = "GET_ABOUT_OFFER_SECTION";
export const GET_ABOUT_OFFER_SECTION_LOADING =
  "GET_ABOUT_OFFER_SECTION_LOADING";

export const GET_ABOUT_FEATURE_SECTION = "GET_ABOUT_FEATURE_SECTION";
export const GET_ABOUT_FEATURE_SECTION_LOADING =
  "GET_ABOUT_FEATURE_SECTION_LOADING";
