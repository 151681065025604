import { GET_TESTIMONIALS, GET_TESTIMONIALS_LOADING } from "./constants";

const initialState = {
  loading: false,
  testimonials: [],
};

export default function testimonial(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TESTIMONIALS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TESTIMONIALS:
      return {
        ...state,
        testimonials: payload.data,
        loading: false,
      };

    default:
      return state;
  }
}
