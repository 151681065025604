import { GET_HEADER_LINKS, GET_HEADER_LINKS_LOADING } from "./constants";

const initialState = {
  loadingLinks: false,
  links: [],
};

export default function header(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_HEADER_LINKS_LOADING:
      return {
        ...state,
        loadingLinks: true,
      };

    case GET_HEADER_LINKS:
      return {
        ...state,
        links: payload.data,
        loadingLinks: false,
      };

    default:
      return state;
  }
}
