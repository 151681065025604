import {
  GET_ABOUT_OFFER_SECTION,
  GET_ABOUT_OFFER_SECTION_LOADING,
  GET_ABOUT_SECTION,
  GET_ABOUT_SECTION_LOADING,
  GET_ABOUT_FEATURE_SECTION,
  GET_ABOUT_FEATURE_SECTION_LOADING,
} from "./constants";

const initialState = {
  aboutSection: null,
  aboutLoading: false,
  offerSection: null,
  offerSectionLoading: false,
  featureSection: null,
  featureSectionLoading: false,
};

export default function about(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ABOUT_SECTION_LOADING:
      return {
        ...state,
        aboutLoading: true,
      };

    case GET_ABOUT_SECTION:
      return {
        ...state,
        aboutSection: payload.data,
        aboutLoading: false,
      };
    case GET_ABOUT_OFFER_SECTION_LOADING:
      return {
        ...state,
        offerSectionLoading: true,
      };

    case GET_ABOUT_OFFER_SECTION:
      return {
        ...state,
        offerSection: payload.data,
        offerSectionLoading: false,
      };
    case GET_ABOUT_FEATURE_SECTION_LOADING:
      return {
        ...state,
        featureSectionLoading: true,
      };

    case GET_ABOUT_FEATURE_SECTION:
      return {
        ...state,
        featureSection: payload.data,
        featureSectionLoading: false,
      };

    default:
      return state;
  }
}
