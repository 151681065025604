import {
  GET_GALLERY_ITEMS,
  GET_GALLERY_ITEMS_LOADING,
  GET_SINGLE_GALLERY_ITEM,
  GET_SINGLE_GALLERY_ITEM_LOADING,
} from "./constants";

const initialState = {
  loading: false,
  galleryItems: [],
  singleItemLoading: false,
  item: {},
};

export default function gallery(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GALLERY_ITEMS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_GALLERY_ITEMS:
      return {
        ...state,
        galleryItems: payload.data,
        loading: false,
      };
    case GET_SINGLE_GALLERY_ITEM_LOADING:
      return {
        ...state,
        singleItemLoading: true,
      };

    case GET_SINGLE_GALLERY_ITEM:
      return {
        ...state,
        item: payload.data,
        singleItemLoading: false,
      };

    default:
      return state;
  }
}
