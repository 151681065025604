import {
  GET_HOME_BANNER,
  GET_HOME_BANNER_LOADING,
  GET_COMMON_BANNER,
  GET_COMMON_BANNER_LOADING,
} from "./constants";

const initialState = {
  homeBanner: null,
  homeBannerLoading: false,
  commonBanner: null,
  commonBannerLoading: false,
};

export default function home(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_HOME_BANNER_LOADING:
      return {
        ...state,
        homeBannerLoading: true,
      };

    case GET_HOME_BANNER:
      return {
        ...state,
        homeBanner: payload.data,
        homeBannerLoading: false,
      };
    case GET_COMMON_BANNER_LOADING:
      return {
        ...state,
        commonBannerLoading: true,
      };

    case GET_COMMON_BANNER:
      return {
        ...state,
        commonBanner: payload.data,
        commonBannerLoading: false,
      };

    default:
      return state;
  }
}
