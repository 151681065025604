import { GET_ENQUIRIES, GET_ENQUIRIES_LOADING } from "./constants";

const initialState = {
  loading: false,
  enquiries: [],
};

export default function inquiry(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ENQUIRIES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ENQUIRIES:
      return {
        ...state,
        enquiries: payload.data,
        loading: false,
      };

    default:
      return state;
  }
}
