import {
  GET_ALL_SERVICES,
  GET_ALL_SERVICES_LOADING,
  GET_SINGLE_SERVICE,
  GET_SINGLE_SERVICE_LOADING,
} from "./constants";

const initialState = {
  serviceSection: null,
  serviceLoading: false,
  services: [],
  servicesLoading: false,
};

export default function service(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SINGLE_SERVICE_LOADING:
      return {
        ...state,
        serviceLoading: true,
      };

    case GET_SINGLE_SERVICE:
      return {
        ...state,
        serviceSection: payload.data,
        serviceLoading: false,
      };
    case GET_ALL_SERVICES_LOADING:
      return {
        ...state,
        servicesLoading: true,
      };

    case GET_ALL_SERVICES:
      return {
        ...state,
        services: payload.data,
        servicesLoading: false,
      };

    default:
      return state;
  }
}
