import { combineReducers } from "redux";
import sidebar from "./modules/Sidebar/reducer";
import auth from "./modules/User/reducer";
import alert from "./modules/Alert/reducer";
import header from "./modules/Header/reducers";
import footer from "./modules/Footer/reducers";
import home from "./modules/Home/reducers";
import about from "./modules/About/reducers";
import service from "./modules/Service/reducers";
import testimonial from "./modules/Testimonial/reducers";
import gallery from "./modules/Gallery/reducers";
import inquiry from "./modules/Contact/reducers";

export default combineReducers({
  sidebar,
  auth,
  alert,
  header,
  footer,
  home,
  about,
  service,
  testimonial,
  gallery,
  inquiry,
});
